import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { api_link, images_root } from '../AdminLogin';

export default class Products extends React.Component {
  constructor() {
    super();
    this.state = {
      products: []
    };

    this.childKey = 0;
  }

  componentDidMount() {
    document.title = "Products"
    this.fetchProducts();
  }

  fetchProducts() {
    let formData = new FormData();
    formData.append('products', "all")
    formData.append('sort_by', "home_and_kitchen")

    axios({
      method: 'post',
      url: api_link + 'ProductsFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ products: data })

        $("script[src='js/dataTable.js']").remove();
        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
  }

  deleteProduct = (qid) => {
    if (window.confirm("Are You Sure To Delete This Product?")) {
      let formData = new FormData();
      formData.append('delete_product', qid)

      axios({
        method: 'post',
        url: api_link + 'ProductsManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            window.location.reload();

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Products</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <Link to="/add_product" className="btn btn-primary" style={{ marginRight: '15px' }}>
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
              </li>
            </ol>
          </section>

          <section className="content" key={this.childKey} style={{ clear: 'both' }}>
            <div className="box">
              <div className="box-body table-responsive">
                <br />

                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th className="noExport">Image</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th className="noExport">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.products?.map((product, key) => (
                      <tr key={product.id}>
                        <td>
                          <img height="117px" alt="p_image" src={images_root + product.product_image} />
                        </td>
                        <td id={'name' + product.id}>{product.name}</td>
                        <td>{product.price}</td>
                        <td>
                          {product.categories?.map((category, key) => (
                            <span key={key}>{category}<br /></span>
                          ))}
                        </td>
                        <td>{product.status}</td>
                        <td>
                          <Link to={"edit_product/" + product.id} className="btn btn-sm btn-success">
                            <i className="fa fa-edit"></i> Edit
                          </Link>
                          <button type="submit" name="del" className="btn btn-sm btn-danger" value={product.id} style={{ marginLeft: '5px' }} 
                            onClick={() => this.deleteProduct(product.id)}><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}