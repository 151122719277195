import { Component } from 'react';
export default class InvalidRequest extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ paddingTop: '60px' }}>

                <div className="breadcrumb-header justify-content-between">
                    <div className="left-content">
                        <div>
                            <h2 className="main-content-title tx-24 mg-b-1 mg-b-lg-1">Page Not Found</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}