import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { api_link } from '../AdminLogin';

export default class CleaningCuttingCharges extends React.Component {
  constructor() {
    super();
    this.state = {
      refresh: false,
      modalVisible: false,
      editModalVisible: false,
      doingModal: false,
      name: '',
      amount: '',
      cleaning: 0,
      charges: []
    };

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteCharge = this.deleteCharge.bind(this);
  }

  componentDidMount() {
    document.title = "Cleaning and Cutting Charge Setup"

    let formData = new FormData();
    formData.append('cleaning_and_cutting', "yes")

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'SetupFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        obj.setState({
          cleaning: data['cleaning_charge'],
          charges: data['cutting_charges']
        })

        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.charges) === JSON.stringify(nextState.charges)) {
      return true;
    }
    else {
      if (this.state.refresh) {
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible
    });
  }

  openEditModal = qid => e => {

    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible,
      name: $("#name" + qid).html(),
      amount: $("#amount" + qid).html(),
      eid: qid
    })

  }

  closeModal() {
    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible
    });
  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let formData = new FormData();
    formData.append('add_charge', 'yes')
    formData.append('name', this.state.name)
    formData.append('amount', this.state.amount)

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'SetupManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        //                $(".loader_gif").hide();
        document.getElementById("addIncentive").reset();
        obj.openModal()
        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('cleaning_and_cutting', "yes")

          axios({
            method: 'post',
            url: api_link + 'SetupFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          }).then(response => response.data)
            .then((data) => {
              obj.setState({ doingModal: false, charges: data['cutting_charges'] })
            })

          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);


      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }

  handleEditFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append('edit_charge', this.state.eid)
    formData.append('ename', this.state.name)
    formData.append('eamount', this.state.amount)

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'SetupManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        document.getElementById("editIncentive").reset();
        obj.closeModal()
        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('cleaning_and_cutting', "yes")
          axios({
            method: 'post',
            url: api_link + 'SetupFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(response => response.data)
            .then((data) => {
              obj.setState({
                doingModal: false,
                cleaning: data['cleaning_charge'],
                charges: data['cutting_charges']
              })
            })

          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);


      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });

  }

  handleCleaningFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append('set_cleaning_charge', this.state.cleaning)

    //var obj=this

    axios({
      method: 'post',
      url: api_link + 'SetupManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success

        if (response.data === "ok") {
          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);


      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }

  deleteCharge = qid => e => {

    if (window.confirm("Are You Sure To Delete?")) {
      let formData = new FormData();
      formData.append('delete_charge', qid)
      var obj = this

      axios({
        method: 'post',
        url: api_link + 'SetupManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('cleaning_and_cutting', "yes")
            axios({
              method: 'post',
              url: api_link + 'SetupFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(response => response.data)
              .then((data) => {
                obj.setState({ doingModal: false, charges: data['cutting_charges'] })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  render() {
    let styles = this.state.modalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    let estyles = this.state.editModalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" };
    let emodalFade = this.state.editModalVisible
      ? { fade: "fade" }
      : { fade: "show" };
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Cleaning and Cutting Charge Setup</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <button title="" className="btn btn-primary" onClick={this.openModal}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </li>
            </ol>
          </section>

          <section className="content">
            <div className="box">
              <div className="box-body table-responsive">
                <h3>Cleaning Charge</h3>
                <br />
                <form onSubmit={this.handleCleaningFormSubmit.bind(this)}>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th style={{ textAlign: 'center' }}>For 1 KG</th>
                        <td>
                          <input type="number" className="form-control" value={this.state.cleaning} onChange={e => this.setState({ cleaning: e.target.value })} />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <button className="btn btn-success">Change</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </section>

          <section className="content" key={this.childKey}>
            <div className="box">
              <div className="box-body table-responsive">
                <h3>Cutting Charge</h3>
                <br />
                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.charges.map((charge, key) => (
                      <tr key={charge.id}>
                        <td id={'name' + charge.id}>{charge.name}</td>
                        <td id={'amount' + charge.id}>{charge.charge}</td>
                        <td>
                          <button type="button" name="edit" className="btn btn-success" value={charge.id} onClick={this.openEditModal(charge.id)} ><i className="fa fa-edit"></i> Edit</button>
                          <button type="button" name="delete" className="btn btn-danger" value={charge.id} style={{ marginLeft: '5px' }} onClick={this.deleteCharge(charge.id)} ><i className="fa fa-trash"></i> Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Cutting Charge</h4>
              </div>
              <form id="addIncentive" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Name</label>
                      <input type="text" className="form-control" id="name" name="name" required onChange={e => this.setState({ name: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Amount</label>
                      <input type="number" className="form-control" id="amount" name="amount" required onChange={e => this.setState({ amount: e.target.value })} />
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div className={'modal ' + emodalFade} id="edit_modal" style={estyles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Edit Cutting Charge</h4>
              </div>
              <form id="editIncentive" onSubmit={this.handleEditFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Name</label>
                      <input type="text" className="form-control" id="ename" name="ename" required value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Amount</label>
                      <input type="number" className="form-control" id="eamount" name="eamount" required value={this.state.amount} onChange={e => this.setState({ amount: e.target.value })} />
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.closeModal}>Close</button>
                  <button type="submit" name="edit" className="btn btn-primary">Edit Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}