import ScrollToTopRoute from "./ScrollToTopRoute";
import InvalidRequest from "./InvalidRequest";
import LoaderMain from "./LoaderMain";
import CountryDropdown from "./CountryDropdown";
import GSTBreakDown from "./GSTBreakDown";
import Parser from 'html-react-parser';
import CropperModal from "./CropperModal";
import $ from 'jquery';

const roundOfNumber = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}
const calculatePrice = (price, gstPercentage) => {
  return roundOfNumber((parseFloat(price) * 100) / parseFloat(100 + parseFloat(gstPercentage)));
}

const calculateGST = (price, gstPercentage) => {
  return roundOfNumber(parseFloat(price) - calculatePrice(price, gstPercentage));
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const copyToClipboard = (containerid, cb) => {
  var text = Parser(document.getElementById(containerid).innerHTML);
  navigator.clipboard.writeText(text);
  cb();
}

const resetDataTable = () => {
  $("script[src='js/dataTable.js']").remove();
  const script = document.createElement("script");
  script.src = "js/dataTable.js";
  document.body.appendChild(script);
}

const loadScript = (src) => {
  $("script[src='" + src + "']").remove();
  let script = document.createElement("script");
  script.src = src;
  document.body.appendChild(script);
}

const numberField = (id, name, number, setNumber, min = -1, max = -1, required = true) => {
  const isNumberKey = (e) => {
    var regex = new RegExp("^[0-9\b]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  if (min >= 0 & max > 0) {
    return (
      <input type="text" className="form-control" id={id} name={name} value={number}
        onKeyDown={(e) => isNumberKey(e)} onChange={(e) => setNumber(e.target.value)} minLength={min} maxLength={max} required={required} />
    );
  }
  return (
    <input type="text" className="form-control" id={id} name={name} value={number}
      onKeyDown={(e) => isNumberKey(e)} onChange={(e) => setNumber(e.target.value)} required={required} />
  );
}

const returnMultiSelectValues = (mainList, selectList) => { //selectList must be comma separated string
  if (selectList) {
    const selectedList = selectList.split(",");
    var filteredList = mainList.filter(item => selectedList.includes(item.value));

    var finalList = [];
    for (var i = 0; i < filteredList.length; i++) {
      finalList.push({ value: filteredList[i].value, label: filteredList[i].label });
    }
    return finalList;
  }
  return null;
}

export {
  ScrollToTopRoute,
  InvalidRequest,
  LoaderMain,
  CountryDropdown,
  roundOfNumber,
  calculatePrice,
  calculateGST,
  monthNames,
  copyToClipboard,
  GSTBreakDown,
  resetDataTable,
  loadScript,
  numberField,
  returnMultiSelectValues,
  CropperModal
}