import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";
import Select from "react-select";
import CKEditor from "react-ckeditor-component";
import { api_link, images_root, editorConfig } from '../AdminLogin';
import { returnMultiSelectValues } from '../helpers/';

const BrandStores = () => {
  const [name, setName] = useState('');
  const [old_name, setOldName] = useState('');
  const [sort_order, setSort_order] = useState('');
  const [image, setImage] = useState('');
  const [eimage, setEimage] = useState('');
  const [eid, setEid] = useState('');
  const [status, setStatus] = useState('Active');
  const [detailed_description, setDetailed_description] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selected_products, setSelectedProducts] = useState(null);

  const fetchBrands = () => {
    let formData = new FormData();
    formData.append('type', "brands")

    axios({
      method: 'post',
      url: api_link + 'CategoriesFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {
        setCategories(data);
      })
  }

  useEffect(() => {
    document.title = "Brand Stores";

    const fetchProducts = () => {
      let formData = new FormData();
      formData.append('fetch_sku_tags', "all")
      formData.append('multi', "yes")

      axios({
        method: 'post',
        url: api_link + 'ProductsFetch.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      }).then(response => response.data)
        .then((data) => {
          setProducts(data);
        })
    }

    fetchProducts();
    fetchBrands();
  }, []);

  const resetFields = () => {
    setName('');
    setOldName('');
    setSort_order('');
    setImage('');
    setEimage('');
    setEid('');
    setStatus('Active');
    setDetailed_description('');
    setSelectedProducts(null);
  }

  const openEditModal = (brand) => {
    setName(brand.name);
    setOldName(brand.name);
    setEimage(brand.image);
    setEid(brand.id);
    setStatus(brand.status);
    setImage('');
    setSort_order(brand.sort_order);
    setSelectedProducts(returnMultiSelectValues(products, brand.sku_products));
    setDetailed_description(brand.sop);

    setEditModalVisible(true);
  }

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();

    if (selected_products && selected_products?.length > 0 && detailed_description && detailed_description?.length > 0) {
      let reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        let formData = new FormData();
        formData.append('type', "brand")
        formData.append('add', 'yes')
        formData.append('name', name)
        formData.append('sort_order', sort_order)
        formData.append('status', status)
        formData.append('image', image)
        formData.append('detailed_description', detailed_description)
        formData.append('sku_products', selected_products?.map(item => item.value).join() || '')

        axios({
          method: 'post',
          url: api_link + 'CategoriesManagement.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then(function (response) {
            //handle success
            document.getElementById("addCategory").reset();
            setModalVisible(false);
            if (response.data === "ok") {
              resetFields();
              fetchBrands();

              $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
              $("#update_message").removeClass("alert-danger").addClass("alert-success")
            }
            else {
              $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
              $("#update_message").removeClass("alert-success").addClass("alert-danger")
            }
            $("#update_message").show()
            setTimeout(function () { $("#update_message").hide();; }, 4000);


          })
          .catch(function (response) {
            //handle error
            console.log(response)
          });
      }
    }
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();

    if (selected_products && selected_products?.length > 0 && detailed_description && detailed_description?.length > 0) {
      //IF IMAGE IS NOT CHANGED
      if (document.getElementById("edit_image").files.length === 0) {
        let formData = new FormData();
        formData.append('edit', eid)
        formData.append('type', "brand")
        formData.append('name', name)
        formData.append('sort_order', sort_order)
        formData.append('status', status)
        formData.append('detailed_description', detailed_description)
        formData.append('sku_products', selected_products?.map(item => item.value).join() || '')

        axios({
          method: 'post',
          url: api_link + 'CategoriesManagement.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then(function (response) {
            //handle success
            document.getElementById("editCategory").reset();
            setEditModalVisible(false);
            if (response.data === "ok") {
              fetchBrands();

              $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
              $("#update_message").removeClass("alert-danger").addClass("alert-success")
            }
            else {
              $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
              $("#update_message").removeClass("alert-success").addClass("alert-danger")
            }
            $("#update_message").show()
            setTimeout(function () { $("#update_message").hide(); }, 4000);
          })
          .catch(function (response) {
            //handle error
            console.log(response)
          });
      }
      else {
        let reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (e) => {
          let formData = new FormData();
          formData.append('edit', eid)
          formData.append('type', "brand")
          formData.append('name', name)
          formData.append('sort_order', sort_order)
          formData.append('status', status)
          formData.append('image', image)
          formData.append('detailed_description', detailed_description)
          formData.append('sku_products', selected_products?.map(item => item.value).join() || '')

          axios({
            method: 'post',
            url: api_link + 'CategoriesManagement.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(function (response) {
              //handle success
              document.getElementById("editCategory").reset();
              setEditModalVisible(false);
              if (response.data === "ok") {
                fetchBrands();

                $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
                $("#update_message").removeClass("alert-danger").addClass("alert-success")
              }
              else {
                $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
                $("#update_message").removeClass("alert-success").addClass("alert-danger")
              }
              $("#update_message").show()
              setTimeout(function () { $("#update_message").hide();; }, 4000);
            })
            .catch(function (response) {
              //handle error
              console.log(response)
            });
        }
      }
    }

  }

  const deleteCategory = (qid) => {
    if (window.confirm("Are You Sure To Delete This Brand?")) {
      let formData = new FormData();
      formData.append('delete_brand', qid)

      axios({
        method: 'post',
        url: api_link + 'CategoriesManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            fetchBrands();

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section>
          <div id="update_message" className="alert" style={{ display: 'none' }}></div>
        </section>
        <section className="content-header">
          <h1>Brand Stores</h1>
          <ol className="breadcrumb">
            <li>
              <div className="pull-right">
                <button title="" className="btn btn-primary" onClick={() => setModalVisible(true)}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </li>
          </ol>
        </section>

        <section className="content">
          <div className="box">
            <MUIDataTable
              data={
                categories?.map((category) => {
                  return [
                    <img height="117px" alt="category_image" src={images_root + category.image} />,
                    category.name,
                    category.sort_order,
                    category.status,
                    <button type="button" name="edit" className="btn btn-sm btn-success" value={category.id} onClick={() => openEditModal(category)}><i className="fa fa-edit"></i></button>,
                    <button type="submit" name="del" className="btn btn-sm btn-danger" value={category.id} onClick={() => deleteCategory(category.id)}><i className="fa fa-trash"></i></button>,
                  ]
                })
              }
              columns={["Image", "Name", "Sort Order", "Status", "Edit", "Delete"]}
              options={{ selectableRows: false }}
            />
          </div>
        </section>
      </div>

      <div className={'modal'} id="add_modal" style={modalVisible
        ? { display: "block", overflow: 'auto' }
        : { display: "none" }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => setModalVisible(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Add Brand</h4>
            </div>
            <form id="addCategory" onSubmit={(e) => handleAddFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={name} required onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Status</label>
                    <select className="form-control" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)} required>
                      <option value="Active">Active</option>
                      <option value="Block">Block</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Sort Order</label>
                    <input type="number" className="form-control" id="sort_order" name="sort_order" required value={sort_order} onChange={e => setSort_order(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <label>Image (Recommended - 1:1)</label>
                    <input type="file" accept="image/*" required onChange={(e) => setImage(e.target.files[0])} />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <label>
                      SKU/Products
                      {
                        (selected_products === null || selected_products?.length === 0)
                        &&
                        <>
                          &nbsp;<span id="product_message" style={{ color: 'red' }}>This field is required*</span>
                        </>
                      }
                    </label>
                    <Select options={products} value={selected_products} isClearable={true} id="products" name="products" required
                      onChange={(selected_products) => setSelectedProducts(selected_products)} isMulti={true} />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <label>
                      SOP
                      {
                        (detailed_description === null || detailed_description?.length === 0)
                        &&
                        <>
                          &nbsp;<span id="d_description" style={{ color: 'red' }}>This field is required*</span>
                        </>
                      }
                    </label>
                    <CKEditor config={editorConfig} content={detailed_description} required activeClass="p10" events={{ "change": e => setDetailed_description(e.editor.getData()) }} />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default pull-left" onClick={() => setModalVisible(false)}>Close</button>
                <button type="submit" name="add" className="btn btn-primary">Add Data</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={'modal'} id="edit_modal" style={editModalVisible
        ? { display: "block", overflow: 'auto' }
        : { display: "none" }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => setEditModalVisible(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Edit Brand</h4>
            </div>
            <form id="editCategory" onSubmit={(e) => handleEditFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Name</label>
                    <input type="text" className="form-control" id="ename" name="ename" value={name} required onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Status</label>
                    <select className="form-control" name="estatus" id="estatus" required value={status} onChange={e => setStatus(e.target.value)}>
                      <option value="Active">Active</option>
                      <option value="Block">Block</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Sort Order</label>
                    <input type="number" className="form-control" id="esort_order" name="esort_order" value={sort_order} required onChange={e => setSort_order(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <img id="eimage" alt="category_image" width="170px" src={`${images_root}${eimage}`} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Image (Recommended - 1:1)</label>
                    <input type="file" id="edit_image" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
                    <input type="hidden" id="old_name" value={old_name} />
                  </div>

                  <div className="col-md-12">
                    <br />
                    <label>
                      SKU/Products
                      {
                        (selected_products === null || selected_products?.length === 0)
                        &&
                        <>
                          &nbsp;<span id="product_message" style={{ color: 'red' }}>This field is required*</span>
                        </>
                      }
                    </label>
                    <Select options={products} value={selected_products} isClearable={true} id="products" name="products" required
                      onChange={(selected_products) => setSelectedProducts(selected_products)} isMulti={true} />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <label>
                      SOP
                      {
                        (detailed_description === null || detailed_description?.length === 0)
                        &&
                        <>
                          &nbsp;<span id="d_description" style={{ color: 'red' }}>This field is required*</span>
                        </>
                      }
                    </label>
                    <CKEditor config={editorConfig} content={detailed_description} required activeClass="p10" events={{ "change": e => setDetailed_description(e.editor.getData()) }} />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default pull-left" onClick={() => setEditModalVisible(false)}>Close</button>
                <button type="submit" name="edit" className="btn btn-primary">Edit Data</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BrandStores;