import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import axios from 'axios';
import AdminLogin, { api_link as link_call, LoginAuth, site_title } from './AdminLogin';
import AdminHome from './AdminHome';

//HANDLE ADMIN PATH
if (window.sessionStorage.getItem(window.btoa("session_admin")) === null || window.sessionStorage.getItem(window.btoa("id")) === null) {
  document.title = "Admin Login";
  $("body").addClass("hold-transition login-page")
  ReactDOM.render(<AdminLogin />, document.getElementById('root'));
}
else {
  let formData = new FormData();
  formData.append('email', window.atob(window.sessionStorage.getItem(window.btoa("session_admin"))))
  formData.append('id', window.atob(window.sessionStorage.getItem(window.btoa("id"))))
  axios({
    method: 'post',
    url: link_call + 'AdminAuth.php',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
    .then(function (response) {
      //handle success
      if (response.data === "ok") {
        LoginAuth.isAuthenticated = true
        document.title = site_title + " | Admin Panel"
        $("body").removeClass("login-page").addClass("sidebar-mini")
        ReactDOM.render(<AdminHome />, document.getElementById('root'));
      }
      else {
        document.title = "Admin Login";
        $("body").addClass("hold-transition login-page")
        ReactDOM.render(<AdminLogin />, document.getElementById('root'));
      }
    })
    .catch(function (response) {
      //handle error
      alert("Some error occured! Please contact Admin!");
      //			alert(JSON.stringify(response));
      console.log(response)
    });
}
ReactDOM.render(<AdminLogin />, document.getElementById('root'));
