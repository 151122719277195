import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { api_link } from '../AdminLogin';

export default class ReorderProducts extends React.Component {
  constructor() {
    super();
    this.state = {
      products: []
    };

    this.childKey = 0;
  }

  componentDidMount() {
    document.title = "Products"
    let formData = new FormData();
    formData.append('products', "all")
    formData.append('sort_by', "home_and_kitchen")
    formData.append('reorder_only', true)

    axios({
      method: 'post',
      url: api_link + 'ProductsFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ products: data })
      })
  }

  render() {
    return (
      <React.Fragment>
        <h3 className="content-header">Reorder Products</h3>

        <div className="col-xs-12" key={this.childKey} style={{ clear: 'both' }}>
          <div className="box">
            <div className="box-body table-responsive">
              <table id="example1" className="table">
                <thead>
                  <tr>
                    <th className="noExport">Image</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Category</th>
                    <th>Hot Offer</th>
                    <th>Quantity</th>
                    <th>ReorderLevel</th>
                    <th>Status</th>
                    <th className="noExport">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((product, key) => (
                    <tr key={product.id} className='danger-row'>
                      <td>
                        <img height="117px" alt="p_image" src={"/images/" + product.product_image} />
                      </td>
                      <td id={'name' + product.id}>{product.name}</td>
                      <td>{product.price}</td>
                      <td>{product.discount}</td>
                      <td>{product.home_and_kitchen_name}</td>
                      <td>{product.hot_offer}</td>
                      <td>{product.quantity}</td>
                      <td>{product.reorder_level}</td>
                      <td>{product.status}</td>
                      <td>
                        <Link to={"edit_product/" + product.id} className="btn btn-success">
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}