import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { api_link } from '../AdminLogin';

export default class ManageCatalogues extends React.Component {
  constructor() {
    super();
    this.state = {
      reseller: '',
      customer: '',
    };

    this.childKey = 0;
  }

  componentDidMount() {
    document.title = "Manage Catalogues";
  }

  handleAddFormSubmit(event) {
    event.preventDefault();

    let reader = new FileReader()
    reader.readAsDataURL(this.state.reseller)
    reader.onload = (e) => {
      let formData = new FormData();
      formData.append('update_reseller', this.state.reseller)
      formData.append('update_reseller_catalogue', "update")

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'ProductsManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            document.getElementById("addForm").reset();
            obj.setState({
              reseller: '',
            })
            $("#update_msg").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_msg").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_msg").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_msg").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_msg").show()
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(function () { $("#update_msg").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
          console.log(response)
        });
    }
  }

  handleEditFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let reader = new FileReader()
    reader.readAsDataURL(this.state.customer)
    reader.onload = (e) => {
      let formData = new FormData();
      formData.append('update_customer', this.state.customer)
      formData.append('update_customer_catalogue', "update")

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'ProductsManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            document.getElementById("editForm").reset();
            obj.setState({
              reseller: '',
            })
            $("#update_msg").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_msg").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_msg").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_msg").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_msg").show()
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(function () { $("#update_msg").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
          console.log(response)
        });
    }
  }

  onChange(e) {
    this.setState({
      reseller: e.target.files[0]
    });
  }


  onEChange(e) {
    this.setState({
      customer: e.target.files[0]
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section className="content-header">
            <h1>Manage Catalogues</h1>
          </section>
          <section>
            <div id="update_msg" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content" style={{ overflow: 'auto' }} key={this.childKey}>
            <div className="box">
              <div className="box-body">
                <br />
                <div className="modal-body">
                  <div className="row">
                    <form id="addForm" className="col-md-6" onSubmit={this.handleAddFormSubmit.bind(this)}>
                      <br />
                      <label>
                        Reseller Catalogue (<a href="/images/ResellerCatalogue.pdf" download="Reseller Catalogue.pdf">Download</a>)
                      </label>
                      <input type="file" className="form-control" accept="application/pdf" required onChange={(e) => this.onChange(e)} />
                      <br />
                      <button type="submit" name="add" className="btn pull-right btn-primary">Update Catalogue</button>
                    </form>
                    <form id="editForm" className="col-md-6" onSubmit={this.handleEditFormSubmit.bind(this)}>
                      <br />
                      <label>
                        Customer Catalogue (<a href="/images/CustomerCatalogue.pdf" download="Customer Catalogue.pdf">Download</a>)
                      </label>
                      <input type="file" className="form-control" accept="application/pdf" required onChange={(e) => this.onEChange(e)} />
                      <br />
                      <button type="submit" name="add" className="btn pull-right btn-primary">Update Catalogue</button>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}