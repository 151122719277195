import { Component } from 'react';
import loader from '../loader.gif';

export default class LoaderMain extends Component{
    render(){
        return(
            <div id="loader-wrapper" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100000, backgroundColor: 'white'}}>
                <div id="loader" style={{display: 'block', position: 'relative', top: '37%', left: '35px', margin: '-10px 0 0 -75px', zIndex: 1500}}> 
                    <center>
                        <img alt="Main Loader" src={loader} style={{width: '150px'}} />
                        <p style={{marginTop: '17px', fontSize: '21px', fontStyle: 'italic', fontWeight: '600'}}>Loading</p>
                    </center>
                </div>
            </div>
        )
    }
}