import React from 'react';
import { api_link } from '../AdminLogin';
import axios from 'axios';
import $ from 'jquery';

export default class SliderSetup extends React.Component {
  constructor() {
    super();
    this.state = {
      sort_order: '',
      image: '',
      refresh: false,
      modalVisible: false,
      doingModal: false,
      sliders: []
    };

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
  }

  onChange(e) {
    this.setState({
      image: e.target.files[0]
    });
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible
    });
  }

  componentDidMount() {
    document.title = "Slider Setup"

    let formData = new FormData();
    formData.append('sliders', "1")

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'EditSiteFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        obj.setState({ sliders: data })
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.sliders) === JSON.stringify(nextState.sliders)) {
      return true;
    }
    else {

      if (this.state.refresh) {
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let reader = new FileReader()
    reader.readAsDataURL(this.state.image)
    reader.onload = (e) => {
      let formData = new FormData();
      formData.append('add_slider', 'yes')
      formData.append('sort_order', this.state.sort_order)
      formData.append('image', this.state.image)
      formData.append('image', this.state.image)
      formData.append('status', $("#add_status").val())

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'EditSiteManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          //                $(".loader_gif").hide();
          document.getElementById("addSlider").reset();
          obj.openModal()
          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('sliders', "1")

            axios({
              method: 'post',
              url: api_link + 'EditSiteFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
              .then(response => response.data)
              .then((data) => {
                obj.setState({ sliders: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide();; }, 4000);


        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
        });
    }
  }

  deleteCategory = qid => e => {

    if (window.confirm("Are You Sure To Delete This Slider Image?")) {
      let formData = new FormData();
      formData.append('delete_slider', qid)
      var obj = this

      axios({
        method: 'post',
        url: api_link + 'EditSiteManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('sliders', "1")

            axios({
              method: 'post',
              url: api_link + 'EditSiteFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
              .then(response => response.data)
              .then((data) => {
                obj.setState({ sliders: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  changeStatus = qid => e => {

    if (window.confirm("Are You Sure To Change The Status?")) {
      let formData = new FormData();
      formData.append('status_slider', qid)
      var obj = this

      axios({
        method: 'post',
        url: api_link + 'EditSiteManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('sliders', "1")

            axios({
              method: 'post',
              url: api_link + 'EditSiteFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
              .then(response => response.data)
              .then((data) => {
                obj.setState({ sliders: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  render() {
    let styles = this.state.modalVisible
      ? { display: "block" }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Slider Setup</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <button title="" className="btn btn-primary" onClick={this.openModal}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </li>
            </ol>
          </section>

          <section className="content" key={this.childKey}>
            <div className="box">
              <div className="box-body table-responsive">
                <br />
                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Sort Order</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sliders.map((slider, key) => (
                      <tr key={slider.id}>
                        <td>
                          <img height="117px" alt="slider" src={"/images/" + slider.image} />
                          <input type="hidden" id={'image' + slider.id} value={"/images/" + slider.image} />
                        </td>
                        <td id={'sort_order' + slider.id}>{slider.sort_order}</td>
                        <td id={'status' + slider.id}>{slider.status}</td>
                        <td>
                          <button type="submit" name="change_status" className="btn btn-primary" value={slider.id} onClick={this.changeStatus(slider.id)}>
                            Change Status
                          </button>
                          <button type="submit" name="del" className="btn btn-danger" value={slider.id} onClick={this.deleteCategory(slider.id)}><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>
        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Slider</h4>
              </div>
              <form id="addSlider" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Image (Recommended - 1920*500)</label>
                      <input type="file" accept="image/*" required onChange={(e) => this.onChange(e)} />
                    </div>
                    <div className="col-md-6">
                      <label>Sort Order</label>
                      <input type="number" className="form-control" id="sort_order" name="sort_order" required onChange={e => this.setState({ sort_order: e.target.value })} />
                    </div>
                    <div className="col-md-6">
                      <label>Status</label>
                      <select className="form-control" id="add_status" required>
                        <option value="active">Active</option>
                        <option value="block">Block</option>
                      </select>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}