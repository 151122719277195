import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { api_link, site_title, images_root } from '../AdminLogin';
import { CropperModal } from '../helpers/';

const HomeAndKitchen = () => {
  const [name, setName] = useState('');
  const [old_name, setOld_name] = useState('');
  const [sort_order, setSort_order] = useState('');
  const [image, setImage] = useState('');
  const [eimage, setEimage] = useState('');
  const [eid, setEid] = useState('');
  const [status, setStatus] = useState('Active');
  const [refresh, setRefresh] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [doingModal, setDoingModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState(1);
  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [cropData, setCropData] = useState("#");

  const onChange = (e) => {
    $(".loader_gif").show();
    // setImage(e.target.files[0]);
    if (e.target.files?.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setCropModalVisible(true);
        $(".loader_gif").fadeOut("slow");
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    else {
      setImage('');
      setCropModalVisible(false);
      $(".loader_gif").fadeOut("slow");
    }
  }

  const handleCropSubmit = (e) => {
    setCropModalVisible(false);
  }

  const openModal = () => {
    setDoingModal(true);
    setModalVisible(!modalVisible);
    setCropData('#');
    setName('');
    setOld_name('');
    setSort_order('');
    setImage('');
    setEimage('');
    setEid('');
    setStatus('Active');
    setImages(1);
  }

  const openEditModal = (qid, e) => {
    $(".loader_gif").show();
    setDoingModal(true);
    setEditModalVisible(!editModalVisible);
    setName($("#name" + qid).html());
    setOld_name($("#name" + qid).html());
    setEimage($("#image" + qid).val());
    setImage('');
    setCropData('#');
    setSort_order($("#sort_order" + qid).html());
    setEid(qid);

    $("#edit_image").val("")
    $("#estatus option").attr("selected", false)
    $("#estatus option[value='" + $("#status" + qid).html() + "']").attr("selected", true)
    setStatus($("#status" + qid).html());

    let formData = new FormData();
    formData.append('fetch_banners', qid)
    formData.append('fetch_type', "home")

    axios({
      method: 'post',
      url: api_link + 'SetupFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {

        var inhtml = '';
        for (let i = 0; i < data.length; i++) {
          inhtml += '<div class="col-xs-8 imgdiv' + data[i].id + '">';
          inhtml += '<img src="' + images_root + data[i].banner + '" style="max-width:250px">';
          inhtml += '</div>';
          inhtml += '<div class="col-xs-2 imgdiv' + data[i].id + '">';
          inhtml += '<button type="button" name="del" class="btn btn-sm btn-danger delImage" value="' + data[i].id + '">';
          inhtml += '<i class="fa fa-trash"></i>';
          inhtml += '</button>';
          inhtml += '</div>';
        }

        $("#ebanner_images").html(inhtml);

        $(".delImage").click(function () {
          if (window.confirm('Are You Sure To Delete This Image?')) {
            var image_id = $(this).val();

            let formData2 = new FormData();
            formData2.append('delete_banner', image_id)

            axios({
              method: 'post',
              url: api_link + 'CategoriesManagement.php',
              data: formData2,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(response => response.data)
              .then((data) => {
                if (data === "ok") {
                  $(".imgdiv" + image_id).remove();
                }
              });
          }
        })

      })

    $(".loader_gif").fadeOut("slow");
  }

  const closeModal = () => {
    setDoingModal(true);
    setEditModalVisible(!editModalVisible);
  }

  useEffect(() => {
    document.title = "Categories | " + site_title

    let formData = new FormData();
    formData.append('type', "home")

    axios({
      method: 'post',
      url: api_link + 'CategoriesFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {
        setCategories(data);
        $("script[src='js/dataTable.js']").remove();
        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      });
  }, []);

  useEffect(() => {
    if (refresh) {
      $("script[src='js/dataTable.js']").remove();
      const script = document.createElement("script");
      script.src = "js/dataTable.js";
      script.async = true;
      document.body.appendChild(script);
      setRefresh(false);
    }
  }, [categories, refresh]);

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();
    $(".loader_gif").show();
    fetch(cropData)
      .then(res => res.blob())
      .then((blobImage) => {
        let formData = new FormData();
        formData.append('type', "home")
        formData.append('name', name)
        formData.append('add', 'yes')
        formData.append('sort_order', sort_order)
        formData.append('status', status);
        formData.append('image', blobImage)

        if ($("[name='b_images[]']").length) {
          $("[name='b_images[]']").each(function () {
            formData.append('banner_images[]', this.files[0]);
          })
        }

        axios({
          method: 'post',
          url: api_link + 'CategoriesManagement.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then(function (response) {
            //handle success
            document.getElementById("addCategory").reset();
            openModal();
            if (response.data === "ok") {
              let formData1 = new FormData();
              formData1.append('type', "home")

              axios({
                method: 'post',
                url: api_link + 'CategoriesFetch.php',
                data: formData1,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
              }).then(response => response.data)
                .then((data) => {
                  setDoingModal(false);
                  setCategories(data);
                })

              $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
              $("#update_message").removeClass("alert-danger").addClass("alert-success")
            }
            else {
              $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
              $("#update_message").removeClass("alert-success").addClass("alert-danger")
            }
            $("#update_message").show()
            setTimeout(function () { $("#update_message").hide();; }, 4000);
            $(".loader_gif").fadeOut("slow");
          })
          .catch(function (response) {
            //handle error
            $(".loader_gif").fadeOut("slow");
            console.log(response)
          });
      });
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();
    $(".loader_gif").show();

    const submitFinalForm = (form) => {
      axios({
        method: 'post',
        url: api_link + 'CategoriesManagement.php',
        data: form,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          document.getElementById("editCategory").reset();
          closeModal();

          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('type', "home")

            axios({
              method: 'post',
              url: api_link + 'CategoriesFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(response => response.data)
              .then((data) => {
                setDoingModal(false);
                setCategories(data);
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);

          $(".loader_gif").fadeOut("slow");
        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
          console.log(response)
        });
    }

    let formData = new FormData();
    formData.append('edit', eid)
    formData.append('type', "home")
    formData.append('name', name)
    formData.append('old_name', old_name)
    formData.append('sort_order', sort_order)
    formData.append('status', status);

    if ($("[name='e_images[]']").length) {
      $("[name='e_images[]']").each(function () {
        formData.append('ebanner_images[]', this.files[0]);
      })
    }

    if (document.getElementById("edit_image").files.length) {
      fetch(cropData)
        .then(res => res.blob())
        .then((blobImage) => {
          formData.append('image', blobImage);
          submitFinalForm(formData);
        });
    } else {
      submitFinalForm(formData);
    }
  }

  const deleteCategory = (qid) => {
    if (window.confirm("Are You Sure To Delete This Category?")) {
      let formData = new FormData();
      formData.append('delete_home_and_kitchen', qid)

      axios({
        method: 'post',
        url: api_link + 'CategoriesManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            window.location.reload();

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  const addImages = () => {
    var html = '<div class="col-md-12 row" id="added_image' + images + '">';
    html += '<div class="col-md-8">';
    html += '<input type="file" required class="form-control" name="b_images[]">';
    html += '</div>';
    html += '<div class="col-md-2">';
    html += '<button type="button" onclick="$(\'#added_image' + images + '\').remove();" data-toggle="tooltip" title="Remove" class="btn btn-sm btn-danger bt-sm pull-right"><i class="fa fa-minus-circle"></i></button></td>';
    html += '</div>';
    html += '</div>';

    $('#banner_images').append(html);

    setImages(images + 1);
  }

  const editImages = () => {
    var html = '<div class="col-md-12 row" id="added_image' + images + '">';
    html += '<div class="col-md-8">';
    html += '<input type="file" required class="form-control" name="e_images[]">';
    html += '</div>';
    html += '<div class="col-md-2">';
    html += '<button type="button" onclick="$(\'#added_image' + images + '\').remove();" data-toggle="tooltip" title="Remove" class="btn btn-sm btn-danger bt-sm pull-right"><i class="fa fa-minus-circle"></i></button></td>';
    html += '</div>';
    html += '</div>';

    $('#ebanner_images').append(html);
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section>
          <div id="update_message" className="alert" style={{ display: 'none' }}></div>
        </section>
        <section className="content-header">
          <h1>Categories</h1>
          <ol className="breadcrumb">
            <li>
              <div className="pull-right">
                <button title="" className="btn btn-sm btn-primary" onClick={() => openModal()}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </li>
          </ol>
        </section>

        <section className="content">
          <div className="box">
            <div className="box-body table-responsive">
              <br />
              <table id="example1" className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Sort Order</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, key) => (
                    <tr key={category.id}>
                      <td>
                        <img height="117px" alt="category_image" src={images_root + category.image} />
                        <input type="hidden" id={'image' + category.id} value={category.image} />
                      </td>
                      <td id={'name' + category.id}>{category.name}</td>
                      <td>
                        {category.sort_order}
                        <p id={'sort_order' + category.id} style={{ display: 'none' }}>{category.sort_order}</p>
                      </td>
                      <td id={'status' + category.id}>{category.status}</td>
                      <td>
                        <button type="button" name="edit" className="btn btn-sm btn-success" value={category.id} onClick={(e) => openEditModal(category.id, e)} ><i className="fa fa-edit"></i> Edit</button>
                        <button type="submit" name="del" className="btn btn-sm btn-danger" value={category.id} style={{ marginLeft: '5px' }}
                          onClick={() => deleteCategory(category.id)}><i className="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </section>
      </div>

      <div className={'modal ' + (!modalVisible ? "fade" : "show")} id="add_modal"
        style={modalVisible ? { display: "block", overflow: 'auto' } : { display: "none" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => openModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Add Category</h4>
            </div>
            <form id="addCategory" onSubmit={(e) => handleAddFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Name</label>
                    <input type="text" className="form-control" id="name" name="name" required onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Status</label>
                    <select className="form-control" name="status" id="status" value={status} onChange={e => setStatus(e.target.value)} required>
                      <option value="Active">Active</option>
                      <option value="Block">Block</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Sort Order</label>
                    <input type="number" className="form-control" id="sort_order" name="sort_order" required onChange={e => setSort_order(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <br />
                    <label>Image (Recommended - 1:1)</label>
                    <input type="file" accept="image/*" id="add_image" required onChange={(e) => onChange(e)} />
                    {
                      cropData !== '#'
                      &&
                      <img src={cropData} alt='Cropped' style={{ maxWidth: '200px', margin: '15px 0' }} />
                    }
                  </div>
                  <div className="col-md-12 hide">
                    <br />
                    <label>Add Banners</label>
                    <button type="button" className="btn btn-sm btn-primary" style={{ marginLeft: '20px' }} onClick={() => addImages()}><i className="fa fa-plus"></i></button>
                    <div id="banner_images" className="row"></div>
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default pull-left" onClick={() => openModal()}>Close</button>
                <button type="submit" name="add" className="btn btn-primary">Add Data</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={'modal ' + (!editModalVisible ? "fade" : "show")} id="edit_modal"
        style={editModalVisible ? { display: "block", overflow: 'auto' } : { display: "none" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Edit Category</h4>
            </div>
            <form id="editCategory" onSubmit={(e) => handleEditFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Name</label>
                    <input type="text" className="form-control" id="ename" name="ename" defaultValue={name} required onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Status</label>
                    <select className="form-control" name="estatus" id="estatus" required defaultValue={status} onChange={e => setStatus(e.target.value)} >
                      <option value="Active">Active</option>
                      <option value="Block">Block</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Sort Order</label>
                    <input type="number" className="form-control" id="esort_order" name="esort_order" defaultValue={sort_order} required onChange={e => setSort_order(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <img id="eimage" alt="category_image" width="170px" src={images_root + eimage} />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Image (Recommended - 1:1)</label>
                    <input type="file" id="edit_image" accept="image/*" onChange={(e) => onChange(e)} />
                    {
                      cropData !== '#'
                      &&
                      <img src={cropData} alt='Cropped' style={{ maxWidth: '200px', margin: '15px 0' }} />
                    }
                    <input type="hidden" id="old_name" defaultValue={old_name} />
                  </div>

                  <div className="col-md-12 hide">
                    <br />
                    <label>Edit Banners</label>
                    <button type="button" className="btn btn-sm btn-primary" style={{ marginLeft: '20px' }} onClick={() => editImages()}><i className="fa fa-plus"></i></button>
                    <div id="ebanner_images" className="row"></div>
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default pull-left" onClick={() => closeModal()}>Close</button>
                <button type="submit" name="edit" className="btn btn-primary">Edit Data</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CropperModal modalVisible={cropModalVisible} setModalVisible={setCropModalVisible} image={image}
        setImage={setImage} setCropData={setCropData} handleFormSubmit={(e) => { handleCropSubmit(e) }} inputID={eid ? 'edit_image' : 'add_image'} />
    </React.Fragment>
  );
}

export default HomeAndKitchen;