import React, { useState, useEffect } from 'react';
import { api_link } from '../AdminLogin';
import axios from 'axios';
import $ from 'jquery';
import MUIDataTable from "mui-datatables";

const BlockCustomers = () => {
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = () => {
    setCustomers([]);
    let formData = new FormData();
    formData.append('block', "yes")

    axios({
      method: 'post',
      url: api_link + 'CustomersFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        setCustomers(data);
      })
  }

  useEffect(() => {
    document.title = "Blocked Franchises"
    fetchCustomers();
  }, []);

  const unblockUser = (userid) => {
    if (window.confirm('Do You Want To Unblock The User? Y/N')) {
      $("#update_message").hide();
      $(".loader_gif").show();

      let formData = new FormData();
      formData.append('enable', userid)

      axios({
        method: 'post',
        url: api_link + 'CustomersManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          $(".loader_gif").fadeOut("slow");
          if (response.data === "ok") {
            fetchCustomers();

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
        });
    }

  }

  return (
    <div className="content-wrapper">
      <section>
        <div id="update_message" className="alert" style={{ display: 'none' }}></div>
      </section>
      <section className="content-header">
        <h1>
          Blocked Franchises
        </h1>
      </section>

      <section className="content">
        <div className="box">
          <MUIDataTable
            data={
              customers?.map((customer, key) => {
                return [
                  customer.name,
                  customer.email,
                  customer.contact,
                  customer.type,
                  customer.state,
                  customer.city,
                  customer.zipcode,
                  <button type="submit" name="block" className="btn btn-success" onClick={() => unblockUser(customer.u_id)}>
                    <i className="fa fa-ban btn-success"></i> Unblock
                  </button>,
                ]
              })
            }
            columns={["Name", "Email", "Contact", "Type", "State", "City", "Pincode", "Unblock"]}
            options={{ selectableRows: false }}
          />
        </div>
      </section>
    </div>
  );
}

export default BlockCustomers;