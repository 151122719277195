import React from 'react';
import { api_link } from '../AdminLogin';
import axios from 'axios';
import $ from 'jquery';

export default class StoreSetup extends React.Component {
  constructor() {
    super();
    this.state = {
      refresh: false,
      modalVisible: false,
      editModalVisible: false,
      doingModal: false,
      title: '',
      address: '',
      landline: '',
      email: '',
      mobile: '',
      timings: '',
      stores: []
    };

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible,
      title: '',
      address: '',
      landline: '',
      email: '',
      mobile: '',
      timings: '',
    });
  }

  componentDidMount() {
    document.title = "Stores"

    let formData = new FormData();
    formData.append('fetch_stores', "all");
    var obj = this;
    axios({
      method: 'post',
      url: api_link + 'SetupFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        obj.setState({ stores: data })
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.stores) === JSON.stringify(nextState.stores)) {
      return true;
    }
    else {
      if (this.state.refresh) {
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let formData = new FormData();
    formData.append('add_store', 'yes')
    formData.append('store_title', this.state.title);
    formData.append('store_address', this.state.address);
    formData.append('store_landline', this.state.landline);
    formData.append('store_email', this.state.email);
    formData.append('store_mobile', this.state.mobile);
    formData.append('store_timings', this.state.timings);

    var obj = this;
    axios({
      method: 'post',
      url: api_link + 'SetupManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        document.getElementById("addFaq").reset();
        obj.openModal()
        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('fetch_stores', "all")

          axios({
            method: 'post',
            url: api_link + 'SetupFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(response => response.data)
            .then((data) => {
              obj.setState({ stores: data })
            })

          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  handleEditFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append('edit_store', this.state.eid)
    formData.append('store_title', this.state.title);
    formData.append('store_address', this.state.address);
    formData.append('store_landline', this.state.landline);
    formData.append('store_email', this.state.email);
    formData.append('store_mobile', this.state.mobile);
    formData.append('store_timings', this.state.timings);

    var obj = this;
    axios({
      method: 'post',
      url: api_link + 'SetupManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        document.getElementById("editStore").reset();
        obj.closeModal()
        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('fetch_stores', "all")

          axios({
            method: 'post',
            url: api_link + 'SetupFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(response => response.data)
            .then((data) => {
              obj.setState({ stores: data })
            })

          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);
        $(".loader_gif").fadeOut("slow");
      })
      .catch(function (response) {
        //handle error
        $(".loader_gif").fadeOut("slow");
        console.log(response)
      });

  }

  deleteLink = qid => e => {
    var obj = this
    if (window.confirm("Are You Sure To Delete?")) {
      let formData = new FormData();
      formData.append('delete_store', qid)

      axios({
        method: 'post',
        url: api_link + 'SetupManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            let filteredArray = obj.state.stores.filter(s => s.id !== qid);
            obj.setState({ stores: filteredArray })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);
        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  openEditModal = (qid, m) => e => {
    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible,
      title: m.title,
      address: m.address,
      landline: m.landline,
      email: m.email,
      mobile: m.mobile,
      timings: m.timings,
      eid: qid
    })
  }

  closeModal() {
    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible,
      title: '',
      address: '',
      landline: '',
      email: '',
      mobile: '',
      timings: '',
    });
  }

  render() {
    let styles = this.state.modalVisible
      ? { display: "block" }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    let estyles = this.state.editModalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" };
    let emodalFade = this.state.editModalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Stores</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <button title="" className="btn btn-primary" onClick={this.openModal}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </li>
            </ol>
          </section>

          <section className="content" key={this.childKey}>
            <div className="box">
              <div className="box-body table-responsive">
                <br />
                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Address</th>
                      <th>Landline</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Timings</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.stores.map((m, key) => (
                      <tr key={`store${key}`}>
                        <td>{m.title}</td>
                        <td>{m.address}</td>
                        <td>{m.landline}</td>
                        <td>{m.email}</td>
                        <td>{m.mobile}</td>
                        <td>{m.timings}</td>
                        <td>
                          <button type="button" name="edit" className="btn btn-success me-2" value={m.id} onClick={this.openEditModal(m.id, m)} ><i className="fa fa-edit"></i> Edit</button>
                          <button type="submit" name="del" className="btn btn-danger" value={m.id} onClick={this.deleteLink(m.id)}><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Store</h4>
              </div>
              <form id="addFaq" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Title</label>
                      <input type="text" className="form-control" required onChange={e => this.setState({ title: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Address</label>
                      <input type="text" className="form-control" required onChange={e => this.setState({ address: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Landline</label>
                      <input type="text" className="form-control" required onChange={e => this.setState({ landline: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Email</label>
                      <input type="email" className="form-control" required onChange={e => this.setState({ email: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Mobile</label>
                      <input type="text" className="form-control" required onChange={e => this.setState({ mobile: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Timings</label>
                      <input type="text" className="form-control" required onChange={e => this.setState({ timings: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={'modal ' + emodalFade} id="edit_modal" style={estyles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Edit FAQ</h4>
              </div>
              <form id="editStore" onSubmit={this.handleEditFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Title</label>
                      <input type="text" className="form-control" required defaultValue={this.state.title} onChange={e => this.setState({ title: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Address</label>
                      <input type="text" className="form-control" required defaultValue={this.state.address} onChange={e => this.setState({ address: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Landline</label>
                      <input type="text" className="form-control" required defaultValue={this.state.landline} onChange={e => this.setState({ landline: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Email</label>
                      <input type="email" className="form-control" required defaultValue={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Mobile</label>
                      <input type="text" className="form-control" required defaultValue={this.state.mobile} onChange={e => this.setState({ mobile: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Timings</label>
                      <input type="text" className="form-control" required defaultValue={this.state.timings} onChange={e => this.setState({ timings: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.closeModal}>Close</button>
                  <button type="submit" name="edit" className="btn btn-primary">Edit Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}