import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { api_link, images_root } from '../AdminLogin';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default class Categories extends React.Component {
  state = {
    name: '',
    parent: '',
    sort_order: '',
    image: '',
    eimage: '',
    eid: '',
    status: '',
    commission_per: '0',
    refresh: false,
    modalVisible: false,
    editModalVisible: false,
    doingModal: false,
    categories: []
  }

  constructor() {
    super();
    this.state = {
      modalVisible: false,
      doingModal: false,
      editModalVisible: false,
      categories: []
    };

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onChange(e) {
    this.setState({
      image: e.target.files[0]
    });
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible
    });

    $('#banner_images').html("")
  }

  openEditModal = qid => e => {

    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible,
      name: $("#name" + qid).html(),
      old_name: $("#name" + qid).html(),
      commission_per: $("#commission_per" + qid).html(),
      eimage: $("#image" + qid).val(),
      image: '',
      sort_order: $("#sort_order" + qid).html(),
      status: $("#status" + qid).html(),
      eid: qid
    })

    $("#edit_image").val("")
    $("#eparent option").attr("selected", false)
    $("#eparent option[value='" + $("#parent" + qid).html() + "']").attr("selected", true)
    $("#estatus option").attr("selected", false)
    $("#estatus option[value='" + $("#status" + qid).html() + "']").attr("selected", true)
    $("#ecleaning option").attr("selected", false)
    $("#ecleaning option[value='" + $("#cleaning" + qid).html() + "']").attr("selected", true)

    //var obj=this;

    let formData = new FormData();
    formData.append('fetch_banners', qid)
    formData.append('fetch_type', "category")

    axios({
      method: 'post',
      url: api_link + 'SetupFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(response => response.data)
      .then((data) => {

        var inhtml = '';
        for (let i = 0; i < data.length; i++) {
          inhtml += '<div class="col-xs-8 imgdiv' + data[i].id + '">';
          inhtml += '<img src="' + images_root + data[i].banner + '" style="max-width:250px">';
          inhtml += '</div>';
          inhtml += '<div class="col-xs-2 imgdiv' + data[i].id + '">';
          inhtml += '<button type="button" name="del" class="btn btn-danger delImage" value="' + data[i].id + '">';
          inhtml += '<i class="fa fa-trash"></i>';
          inhtml += '</button>';
          inhtml += '</div>';
        }

        $("#ebanner_images").html(inhtml);

        $(".delImage").click(function () {
          if (window.confirm('Are You Sure To Delete This Image?')) {
            var image_id = $(this).val();

            let formData2 = new FormData();
            formData2.append('delete_banner', image_id)

            axios({
              method: 'post',
              url: api_link + 'CategoriesManagement.php',
              data: formData2,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(response => response.data)
              .then((data) => {
                if (data === "ok") {
                  $(".imgdiv" + image_id).remove();
                }
              });
          }
        })
      })

  }

  closeModal() {
    this.setState({
      doingModal: true,
      editModalVisible: !this.state.editModalVisible
    });
  }

  componentDidMount() {
    document.title = "Categories"
    const url = api_link + 'CategoriesFetch.php'
    axios.get(url).then(response => response.data)
      .then((data) => {
        this.setState({ categories: data })
        //			  console.log(this.state.questions)
        $("script[src='js/dataTable.js']").remove();
        const script = document.createElement("script");

        script.src = "js/dataTable.js";
        script.async = true;

        document.body.appendChild(script);
      })

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.categories) === JSON.stringify(nextState.categories)) {
      return true;
    }
    else {
      if (this.state.refresh) {
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    //        $(".loader_gif").show();
    let reader = new FileReader()
    reader.readAsDataURL(this.state.image)
    reader.onload = (e) => {

      let formData = new FormData();
      formData.append('name', this.state.name)
      formData.append('add', 'yes')
      formData.append('sort_order', this.state.sort_order)
      formData.append('parent', this.refs.parent.value)
      formData.append('status', this.refs.c_status.value)
      formData.append('image', this.state.image)
      formData.append('commission_per', this.state.commission_per)
      formData.append('cleaning', $("#cleaning").val());

      if ($("[name='b_images[]']").length) {
        $("[name='b_images[]']").each(function () {
          formData.append('banner_images[]', this.files[0]);
        })
      }

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'CategoriesManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          document.getElementById("addCategory").reset();
          obj.openModal()
          if (response.data === "ok") {
            const url = api_link + 'CategoriesFetch.php'
            axios.get(url).then(response => response.data)
              .then((data) => {
                obj.setState({ doingModal: false, categories: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide();; }, 4000);


        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
          console.log(response)
        });
    }
  }

  handleEditFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    if (document.getElementById("edit_image").files.length === 0) {
      let formData = new FormData();
      formData.append('edit', this.state.eid)
      formData.append('name', this.state.name)
      formData.append('old_name', this.state.old_name)
      formData.append('sort_order', this.state.sort_order)
      formData.append('commission_per', this.state.commission_per)
      formData.append('parent', this.refs.eparent.value)
      formData.append('status', this.refs.e_status.value)
      formData.append('cleaning', $("#ecleaning").val())

      if ($("[name='e_images[]']").length) {
        $("[name='e_images[]']").each(function () {
          formData.append('ebanner_images[]', this.files[0]);
        })
      }

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'CategoriesManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          //                $(".loader_gif").hide();
          document.getElementById("editCategory").reset();
          obj.closeModal()
          if (response.data === "ok") {
            const url = api_link + 'CategoriesFetch.php'
            axios.get(url).then(response => response.data)
              .then((data) => {
                obj.setState({ doingModal: false, categories: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          //			alert(JSON.stringify(response));
          $(".loader_gif").fadeOut("slow");
          console.log(response)
        });
    }
    else {
      let reader = new FileReader()
      reader.readAsDataURL(this.state.image)
      reader.onload = (e) => {
        let formData = new FormData();
        formData.append('edit', this.state.eid)
        formData.append('name', this.state.name)
        formData.append('old_name', this.state.old_name)
        formData.append('sort_order', this.state.sort_order)
        formData.append('parent', this.refs.parent.value)
        formData.append('image', this.state.image)
        formData.append('commission_per', this.state.commission_per)

        var obj = this

        axios({
          method: 'post',
          url: api_link + 'CategoriesManagement.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then(function (response) {
            //handle success

            document.getElementById("editCategory").reset();
            obj.closeModal()
            if (response.data === "ok") {
              const url = api_link + 'CategoriesFetch.php'
              axios.get(url).then(response => response.data)
                .then((data) => {
                  obj.setState({ doingModal: false, categories: data })
                })

              $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
              $("#update_message").removeClass("alert-danger").addClass("alert-success")
            }
            else {
              $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
              $("#update_message").removeClass("alert-success").addClass("alert-danger")
            }
            $("#update_message").show()
            setTimeout(function () { $("#update_message").hide();; }, 4000);


          })
          .catch(function (response) {
            //handle error
            //			alert(JSON.stringify(response));
            console.log(response)
          });
      }
    }

  }

  /*deleteCategory = qid  => e => {
    
        if(window.confirm("Are You Sure To Delete This Category?")) 
        {
            let formData = new FormData();
            formData.append('did', qid)
            var obj=this

            axios({
                method: 'post',
                url: api_link+'CategoriesManagement.php',
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(function (response) {
                //handle success
                if(response.data === "ok")
                {	
                    const url = api_link+'CategoriesFetch.php'
                    axios.get(url).then(response => response.data)
                    .then((data) => {
                      obj.setState({ doingModal: false, categories: data})
                     })

                    $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
                    $("#update_message").removeClass("alert-danger").addClass("alert-success")
                }
                else
                {
                    $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
                    $("#update_message").removeClass("alert-success").addClass("alert-danger")
                }
                $("#update_message").show()
                 setTimeout(function(){ $("#update_message").hide(); }, 4000);


            })
            .catch(function (response) {
                //handle error
    //			alert(JSON.stringify(response));
                console.log(response)
            });
        }
    }*/

  render() {
    let styles = this.state.modalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    let estyles = this.state.editModalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" };
    let emodalFade = this.state.editModalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    var images = 1;
    //var eimages=1;
    function addImages() {
      var html = '<div class="col-md-12 row" id="added_image' + images + '">';
      html += '<div class="col-md-8">';
      html += '<input type="file" required class="form-control" name="b_images[]">';
      html += '</div>';
      html += '<div class="col-md-2">';
      html += '<button type="button" onclick="$(\'#added_image' + images + '\').remove();" data-toggle="tooltip" title="Remove" class="btn btn-danger bt-sm pull-right"><i class="fa fa-minus-circle"></i></button></td>';
      html += '</div>';
      html += '</div>';

      $('#banner_images').append(html);

      images++;
    }

    function editImages() {
      var html = '<div class="col-md-12 row" id="added_image' + images + '">';
      html += '<div class="col-md-8">';
      html += '<input type="file" required class="form-control" name="e_images[]">';
      html += '</div>';
      html += '<div class="col-md-2">';
      html += '<button type="button" onclick="$(\'#added_image' + images + '\').remove();" data-toggle="tooltip" title="Remove" class="btn btn-danger bt-sm pull-right"><i class="fa fa-minus-circle"></i></button></td>';
      html += '</div>';
      html += '</div>';

      $('#ebanner_images').append(html);

      //eimages++;
    }

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Categories</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <button title="" className="btn btn-primary" onClick={this.openModal}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </li>
            </ol>
          </section>

          <section className="content" key={this.childKey}>
            <div className="box">
              <div className="box-body table-responsive">
                <br />
                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Parent</th>
                      <th>Sort Order</th>
                      <th>Status</th>
                      <th>Cleaning</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.categories.map((category, key) => (
                      <tr key={category.id}>
                        <td>
                          <img height="117px" alt="category_image" src={images_root + category.image} />
                          <input type="hidden" id={'image' + category.id} value={"/images/" + category.image} />
                        </td>
                        <td id={'name' + category.id}>{category.name}</td>
                        <td id={'parent' + category.id}>{entities.decode(category.parent)}</td>
                        <td>
                          {category.sort_order}
                          <p id={'sort_order' + category.id} style={{ display: 'none' }}>{category.sort_order}</p>
                          <p id={'commission_per' + category.id} style={{ display: 'none' }}>{category.commission_per}</p>
                        </td>
                        <td id={'status' + category.id}>{category.status}</td>
                        <td id={'cleaning' + category.id}>
                          {
                            category.cleaning_and_cutting === "0"
                              ?
                              "No"
                              :
                              "Yes"
                          }
                        </td>
                        <td>
                          <button type="button" name="edit" className="btn btn-success" value={category.id} onClick={this.openEditModal(category.id)} ><i className="fa fa-edit"></i> Edit</button>
                          {/*<button type="submit" name="del" className="btn btn-danger" value={category.id} onClick={this.deleteCategory(category.id)}><i className="fa fa-trash"></i></button>*/}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Category</h4>
              </div>
              <form id="addCategory" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name</label>
                      <input type="text" className="form-control" id="name" name="name" required onChange={e => this.setState({ name: e.target.value })} />
                    </div>
                    <div className="col-md-6">
                      <label>Status</label>
                      <select className="form-control" name="status" id="status" required ref="c_status" >
                        <option value="Active">Active</option>
                        <option value="Block">Block</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Parent</label>
                      <select className="form-control" name="parent" id="parent" data-live-search="true" required ref="parent" >
                        <option value="None">None</option>
                        {this.state.categories.map((category, key) => {
                          if (category.parent === "None") {
                            return (<option key={category.id} value={category.name}>{category.name}</option>);
                          }
                          else {
                            return '';
                          }
                        })}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Sort Order</label>
                      <input type="number" className="form-control" id="sort_order" name="sort_order" required onChange={e => this.setState({ sort_order: e.target.value })} />
                    </div>
                    <div className="col-md-6" style={{ display: 'none' }}>
                      <br />
                      <label>Commission (In %)</label>
                      <input type="number" className="form-control" id="commission_per" name="commission_per" defaultValue="0" required onChange={e => this.setState({ commission_per: e.target.value })} />
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Image (Recommended - 1:1)</label>
                      <input type="file" accept="image/*" required onChange={(e) => this.onChange(e)} />
                    </div>

                    <div className="col-md-6">
                      <br />
                      <label>Cutting And Cleaning</label>
                      <select className="form-control" name="cleaning" id="cleaning" required>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <br />
                      <label>Add Banners</label>
                      <button type="button" className="btn btn-primary" style={{ marginLeft: '20px' }} onClick={() => addImages()}><i className="fa fa-plus"></i></button>
                      <div id="banner_images" className="row"></div>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={'modal ' + emodalFade} id="edit_modal" style={estyles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Edit Category</h4>
              </div>
              <form id="editCategory" onSubmit={this.handleEditFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name</label>
                      <input type="text" className="form-control" id="ename" name="ename" defaultValue={this.state.name} required onChange={e => this.setState({ name: e.target.value })} />
                    </div>
                    <div className="col-md-6">
                      <label>Status</label>
                      <select className="form-control" name="estatus" id="estatus" required ref="e_status" defaultValue={this.state.status}>
                        <option value="Active">Active</option>
                        <option value="Block">Block</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Parent</label>
                      <select className="form-control" name="eparent" id="eparent" required ref="eparent" >
                        <option value="None">None</option>
                        {this.state.categories.map((category, key) => {
                          if (category.parent === "None" && category.name !== this.state.name) {
                            return (<option key={category.id} value={category.name}>{category.name}</option>);
                          }
                          else {
                            return '';
                          }
                        })}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Sort Order</label>
                      <input type="number" className="form-control" id="esort_order" name="esort_order" value={this.state.sort_order} required onChange={e => this.setState({ sort_order: e.target.value })} />
                    </div>
                    <div className="col-md-6" style={{ display: 'none' }}>
                      <br />
                      <label>Commission (In %)</label>
                      <input type="number" className="form-control" id="ecommission_per" name="ecommission_per" value={this.state.commission_per} required onChange={e => this.setState({ commission_per: e.target.value })} />
                    </div>
                    <div className="col-md-6">
                      <br />
                      <img id="eimage" alt="category_image" width="170px" src={images_root + this.state.eimage} />
                    </div>
                    <div className="col-md-6">
                      <br />
                      <label>Image (Recommended - 1:1)</label>
                      <input type="file" id="edit_image" accept="image/*" onChange={(e) => this.onChange(e)} />
                      <input type="hidden" id="old_name" value={this.state.old_name} />
                      <br />
                      <label>Cutting And Cleaning</label>
                      <select className="form-control" name="ecleaning" id="ecleaning" required>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <br />
                      <label>Edit Banners</label>
                      <button type="button" className="btn btn-primary" style={{ marginLeft: '20px' }} onClick={() => editImages()}><i className="fa fa-plus"></i></button>
                      <div id="ebanner_images" className="row"></div>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.closeModal}>Close</button>
                  <button type="submit" name="edit" className="btn btn-primary">Edit Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}