import React, { useState } from 'react';
import Cropper from "react-cropper";
import $ from 'jquery';
import "cropperjs/dist/cropper.css";

const CropperModal = ({ modalVisible, setModalVisible, handleFormSubmit, image, setImage, setCropData, inputID, ...props }) => {
  const [cropper, setCropper] = useState();

  const getCropData = async (e) => {
    e.preventDefault();
    $(".loader_gif").show();
    if (typeof cropper !== "undefined") {
      await setCropData(cropper.getCroppedCanvas().toDataURL());
    }
    handleFormSubmit(e);
    $(".loader_gif").fadeOut("slow");
  };

  const onClose = () => {
    setImage('');
    if (inputID) {
      document.getElementById(inputID).value = "";
    }
    setModalVisible(false);
  }


  return (
    <div className={'modal'} id="crop_modal" style={modalVisible
      ? { display: "block", overflow: 'auto' }
      : { display: "none" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={() => setModalVisible(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Crop Image</h4>
          </div>
          <form id="addCategory" onSubmit={(e) => getCropData(e)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <Cropper
                    zoomTo={0}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                    cropBoxResizable={false}
                    rotatable={false}
                    scalable={false}
                    dragMode={'move'}
                    toggleDragModeOnDblclick={false}
                    style={{ height: 350, width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default pull-left" onClick={onClose}>Close</button>
              <button type="submit" name="add" className="btn btn-primary">Crop Image</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CropperModal;