import React from 'react';
import { api_link } from '../AdminLogin';
import axios from 'axios';
import $ from 'jquery';

export default class SocialSetup extends React.Component {
  constructor() {
    super();
    this.state = {
      refresh: false,
      modalVisible: false,
      doingModal: false,
      media: '',
      link: '',
      medias: []
    };

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible
    });
  }

  componentDidMount() {
    document.title = "Slider Setup"

    let formData = new FormData();
    formData.append('social_media', "1")

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'EditSiteFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        obj.setState({ medias: data })
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.medias) === JSON.stringify(nextState.medias)) {
      return true;
    }
    else {

      if (this.state.refresh) {
        $("script[src='js/dataTable.js']").remove();

        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let formData = new FormData();
    formData.append('add_media', 'yes')
    formData.append('media', this.refs.social_media.value)
    formData.append('link', this.state.social_link)

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'EditSiteManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        document.getElementById("addMedia").reset();
        obj.openModal()
        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('social_media', "1")

          axios({
            method: 'post',
            url: api_link + 'EditSiteFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(response => response.data)
            .then((data) => {
              obj.setState({ medias: data })
            })

          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide();; }, 4000);


      })
      .catch(function (response) {
        //handle error
        $(".loader_gif").fadeOut("slow");
      });
  }

  deleteLink = qid => e => {

    if (window.confirm("Are You Sure To Delete This Social Link?")) {
      let formData = new FormData();
      formData.append('delete_media', qid)
      var obj = this

      axios({
        method: 'post',
        url: api_link + 'EditSiteManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            let formData1 = new FormData();
            formData1.append('social_media', "1")

            axios({
              method: 'post',
              url: api_link + 'EditSiteFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
              .then(response => response.data)
              .then((data) => {
                obj.setState({ medias: data })
              })

            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  render() {
    let styles = this.state.modalVisible
      ? { display: "block" }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Social Links Setup</h1>
            <ol className="breadcrumb">
              <li>
                <div className="pull-right">
                  <button title="" className="btn btn-primary" onClick={this.openModal}>
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </li>
            </ol>
          </section>

          <section className="content" key={this.childKey}>
            <div className="box">
              <div className="box-body table-responsive">
                <br />
                <table id="example1" className="table">
                  <thead>
                    <tr>
                      <th>Media</th>
                      <th>Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.medias.map((m, key) => (
                      <tr key={m.id}>
                        <td id={'media' + m.id}><i className={"fab " + m.icon}></i> {m.head}</td>
                        <td id={'link' + m.id}>{m.link}</td>
                        <td>
                          <button type="submit" name="del" className="btn btn-danger" value={m.id} onClick={this.deleteLink(m.id)}><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>
        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add Social Account</h4>
              </div>
              <form id="addMedia" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Social Media</label>
                      <select className="form-control" required ref="social_media">
                        <option value="fa-facebook">Facebook</option>
                        <option value="fa-twitter">Twitter</option>
                        <option value="fa-google">Google</option>
                        <option value="fa-linkedin">LinkedIn</option>
                        <option value="fa-youtube">YouTube</option>
                        <option value="fa-instagram">Instagram</option>
                        <option value="fa-pinterest">Pinterest</option>
                        <option value="fa-snapchat-ghost">Snapchat</option>
                        <option value="fa-skype">Skype</option>
                        <option value="fa-android">Android</option>
                        <option value="fa-dribbble">Dribble</option>
                        <option value="fa-vimeo">Vimeo</option>
                        <option value="fa-tumblr">Tumble</option>
                        <option value="fa-vine">Vine</option>
                        <option value="fa-foursquare">FourSquare</option>
                        <option value="fa-stumbleupon">StumbleUpon</option>
                        <option value="fa-flickr">Flickr</option>
                        <option value="fa-yahoo">Yahoo</option>
                        <option value="fa-reddit">Reddit</option>
                        <option value="fa-rss">RSS</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Social Link</label>
                      <input type="url" className="form-control" required onChange={e => this.setState({ social_link: e.target.value })} />
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}