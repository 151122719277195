import React from 'react';
import { api_link, editorConfig } from '../AdminLogin';
import axios from 'axios';
import $ from 'jquery';
import CKEditor from "react-ckeditor-component";

export default class DynamicPages extends React.Component {

  constructor(props) {
    super();
    this.state = {
      page_data: '',
      page_link: '',
      page_title: '',
      eid: '',
      refresh: false,
      modalVisible: false,
      doingModal: false,
      pages: []
    }

    this.childKey = 0;
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {

    let formData = new FormData();
    formData.append('dynamic_pages', "1")

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'EditSiteFetch.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        obj.setState({ pages: data })
      })

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.pages) === JSON.stringify(nextState.pages)) {
      return true;
    }
    else {
      if (this.state.refresh) {
        ++this.childKey
      }
      else {
        this.setState({
          refresh: true
        })
      }
      return true;
    }
  }

  onChange(e) {
    var id = e.target.value;

    if (id === "None") {
      this.setState({
        page_link: '',
        page_data: '',
        eid: '',
      })

      $("#page_link").attr("disabled", true)
      $("#delete").hide()
    }
    else {
      let formData = new FormData();
      formData.append('get_page_data', id)

      var obj = this
      axios({
        method: 'post',
        url: api_link + 'EditSiteFetch.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(response => response.data)
        .then((data) => {
          obj.setState({
            page_link: data['page_name'],
            page_data: data['page_data'],
            eid: id
          })
        })
      $("#page_link").attr("disabled", false)
      $("#delete").show()
    }
    //        this.setState({
    //            image: e.target.files[0]
    //        });
  }

  openModal() {
    this.setState({
      doingModal: true,
      modalVisible: !this.state.modalVisible
    });
  }

  handleEditFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append('edit_page', this.state.eid)
    formData.append('page_name', this.state.page_link)
    formData.append('page_data', this.state.page_data)

    axios({
      method: 'post',
      url: api_link + 'EditSiteManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        if (response.data === "ok") {
          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else if (response.data === "already") {
          $("#update_message").html("<strong>Error! </strong> Page Name Already Exists!")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);


      })
      .catch(function (response) {
        //handle error
        $(".loader_gif").fadeOut("slow");
      });

  }

  handleAddFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append('add_page', this.state.eid)
    formData.append('page_name', this.state.page_link)
    formData.append('page_title', this.state.page_title)

    var obj = this

    axios({
      method: 'post',
      url: api_link + 'EditSiteManagement.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        obj.openModal();
        document.getElementById("addPage").reset();

        if (response.data === "ok") {
          let formData1 = new FormData();
          formData1.append('dynamic_pages', "1")

          axios({
            method: 'post',
            url: api_link + 'EditSiteFetch.php',
            data: formData1,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then(response => response.data)
            .then((data) => {
              obj.setState({
                pages: data,
                page_link: '',
                page_data: ''
              })
            })
          $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
          $("#update_message").removeClass("alert-danger").addClass("alert-success")
        }
        else {
          $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
          $("#update_message").removeClass("alert-success").addClass("alert-danger")
        }
        $("#update_message").show()
        setTimeout(function () { $("#update_message").hide(); }, 4000);


      })
      .catch(function (response) {
        //handle error
        $(".loader_gif").fadeOut("slow");
      });

  }

  deletePage(event) {

    if (window.confirm("Are You Sure To Delete This Page?")) {
      let formData = new FormData();
      formData.append('delete_page', this.state.eid)

      var obj = this

      axios({
        method: 'post',
        url: api_link + 'EditSiteManagement.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            document.getElementById("pageForm").reset()

            let formData1 = new FormData();
            formData1.append('dynamic_pages', "1")

            axios({
              method: 'post',
              url: api_link + 'EditSiteFetch.php',
              data: formData1,
              config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
              .then(response => response.data)
              .then((data) => {
                obj.setState({
                  pages: data,
                  page_link: '',
                  page_data: '',
                })
              })

            $("#page_link").attr("disabled", true)
            $("#delete").hide()
            $("#update_message").html("<strong>Success! </strong> Your Request Successfully Processed")
            $("#update_message").removeClass("alert-danger").addClass("alert-success")
          }
          else {
            $("#update_message").html("<strong>Error! </strong> Unable to Process Your Request")
            $("#update_message").removeClass("alert-success").addClass("alert-danger")
          }
          $("#update_message").show()
          setTimeout(function () { $("#update_message").hide(); }, 4000);


        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }
  }

  render() {
    let styles = this.state.modalVisible
      ? { display: "block" }
      : { display: "none" };
    let modalFade = this.state.modalVisible
      ? { fade: "fade" }
      : { fade: "show" };

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section>
            <div id="update_message" className="alert" style={{ display: 'none' }}></div>
          </section>
          <section className="content-header">
            <h1>Page Setup
              <div className="pull-right">
                <button title="" className="btn btn-primary" onClick={this.openModal}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </h1>
          </section>

          <section className="content">
            <form id="pageForm" method="post" onSubmit={this.handleEditFormSubmit.bind(this)}>
              <div className="box">
                <div className="box-body">
                  <div className="row container">
                    <div className="form-group row">
                      <label className="col-md-2 control-label" style={{ textAlign: 'right' }}>Select Page</label>
                      <div className="col-md-8">
                        <select className="form-control" id="page" name="page" onChange={(e) => this.onChange(e)}>
                          <option>None</option>
                          {this.state.pages.map((page, key) => (
                            <option value={page.id} key={key}>{page.title}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-2 control-label" style={{ textAlign: 'right' }}>Page Link</label>
                      <div className="col-md-8">
                        <input type="text" className="form-control" value={this.state.page_link} onChange={e => this.setState({ page_link: e.target.value })} id="page_link" name="page_link" disabled />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-2 control-label" style={{ textAlign: 'right' }}>Page Description</label>
                      <div className="col-md-8">
                        <CKEditor config={editorConfig} content={this.state.page_data} required activeClass="p10" events={{ "change": e => this.setState({ page_data: e.editor.getData() }) }} />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="form-group">
                        <br />
                        <button type="submit" name="details" id="details" className="btn btn-primary pull-right">Save Changes</button>
                        <button type="button" id="delete" onClick={(e) => this.deletePage(e)} className="btn btn-danger pull-right" style={{ marginRight: '5px', display: 'none' }}>Delete Page Data</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        <div className={'modal ' + modalFade} id="add_modal" style={styles}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.openModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Add New Page</h4>
              </div>
              <form id="addPage" onSubmit={this.handleAddFormSubmit.bind(this)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Page Title</label>
                      <input type="text" className="form-control" id="name" name="name" required onChange={e => this.setState({ page_title: e.target.value })} />
                    </div>
                    <div className="col-md-12">
                      <br />
                      <label>Page Name</label>
                      <input type="text" className="form-control" id="name" name="name" required onChange={e => this.setState({ page_link: e.target.value })} />
                    </div>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left" onClick={this.openModal}>Close</button>
                  <button type="submit" name="add" className="btn btn-primary">Add Data</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>

    );
  }
}